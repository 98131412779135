

















































































import { defineComponent, reactive, toRefs, ref } from '@vue/composition-api';
import axios from 'axios';

export default defineComponent({
  name: 'CreateTeam',
  props: {
    programDoc: {
      required: false,
      type: Object,
      default: () => {}
    },
    updateAdk: {
      type: Function,
      default: () => {}
    },
    programId: {
      type: String,
      default: ''
    },
    teamList: {
      type: Array,
      default: () => []
    }
  },
  setup(props, ctx) {
    const state = reactive({
      name: '',
      password: '',
      createTeamDialog: false
    });
    const isLoading = ref(false);

    async function createTeam() {
      try {
        isLoading.value = true;
        const data = {
          program_id:
            ctx.root.$route.params.programId ||
            props?.programId ||
            props?.programDoc?.data?.program_id?.toString(),
          public_id: props?.programDoc?.data?._id?.toString(),
          name: state.name,
          password: state.password
        };

        const teamRes = await axios.post(`${process.env.VUE_APP_STUDENT_SERVICE}/team`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
          }
        });

        if (teamRes.data.data) {
          ctx.emit('update', teamRes.data.data);
          await props.updateAdk();
          isLoading.value = false;
          state.createTeamDialog = false;
          state.name = '';
          state.password = '';
        }
      } catch (error) {
        console.log(error);
        isLoading.value = false;
      }
    }

    return { ...toRefs(state), createTeam, isLoading };
  }
});
